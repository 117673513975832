import React, { Component, Fragment } from "react";
import { $managementAxios } from "../../lib/unility/http-service";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { JsonToCsvFile } from "../../lib/unility/csv-helper";
import { JsonToQueryParams } from "../../lib/unility/json-helper";
import DateRangePicker from "rsuite/DateRangePicker";
import {
	Drawer,
	Card,
	CardGroup,
	VStack,
	Text,
	Tooltip,
	Whisper,
} from "../../lib/unility/rsuite-helper";
import { AvaterOne } from "../../lib/unility/image-helper";

class PropertiesBookingReqList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{
					name: "#",
					selector: (row) => row.bookingCode,
					id: "bookingCode",
					sortable: true,
				},
				{
					name: "Host",
					selector: (row) =>
						`${row.hostAccount?.firstName || ""} ${
							row.hostAccount?.lastName || ""
						}`,
					sortable: true,
				},
				{
					name: "Guest",
					selector: (row) =>
						`${row.guestAccount?.firstName || ""} ${
							row.guestAccount?.lastName || ""
						}`,
					sortable: true,
				},
				{
					name: "Phone",
					selector: (row) => row.guestAccount?.phoneNumber || "",
					sortable: true,
				},
				{
					name: "Title",
					selector: (row) => row.property?.name || "",
					sortable: true,
				},
				{
					name: "Total",
					selector: (row) => row.feesTotal ?? "",
					sortable: true,
				},
				{
					name: "Status",
					selector: (row) => row.bookingStatus ?? "",
					sortable: false,
					cell: (row) => (
						<this.StatusBadge value={row.bookingStatus} />
					),
				},
				{
					name: "Booking Date",
					selector: (row) => row.bookingDate,
					sortable: true,
				},
				{
					name: "CheckIn Date",
					selector: (row) => row.checkInDate,
					format: (row, index) => row.checkInDate,
					sortable: true,
				},
				{
					name: "CheckOut Date",
					selector: (row) => row.checkOutDate,
					format: (row, index) => row.checkOutDate,
					sortable: true,
				},
				//{
				// 	name: "Action",
				// 	cell: (row) => (
				// 		<div className='d-flex flex-row justify-content-center'>
				// 			<div>
				// 				<i
				// 					className='btn icofont icofont-eye p-2'
				// 					onClick={() => {
				// 						this.viewBookingDetails(row);
				// 					}}
				// 				></i>
				// 			</div>
				// 		</div>
				// 	),
				// 	button: true,
				// },
			],
			loading: false,
			data: {},
			parameters: {
				pageNo: 1,
				pageSize: 20,
				bookingConfirmation: "PENDING",
				bookingCode: null,
				sortBy: "id:desc",
				fromDate: null,
				toDate: null,
				guestName: null,
				guestPhone: null,
				guestEmail: null,
			},
			bookingConfirmation: "PENDING",
			bookingCode: "",
			fromDate: null,
			toDate: null,
			selectedId: null,
			dateRange: [null, null],
			exportData: [],
			isDrawerOpen: false,
			breadcrumb: [{ name: "Reservation Management" }],
			selectedBooking: null,
		};
	}

	showLoader() {
		this.setState({ loading: true });
	}

	closeLoader() {
		this.setState({ loading: false });
	}

	setDrawerOpen = (value) => {
		this.setState({ isDrawerOpen: value });
	};

	componentDidMount() {
		const storedParameters = JSON.parse(
			localStorage.getItem("bookingParameters")
		);

		if (storedParameters) {
			this.setState({ parameters: storedParameters });
			this.updateParameters(storedParameters);
		}
		setTimeout(() => {
			this.getData();
		}, 1000);
	}

	updateParameters = () => {
		localStorage.setItem(
			"bookingParameters",
			JSON.stringify(this.state.parameters)
		);
	};

	getData = () => {
		this.setState({ loading: true });
		let _filtersUrl = JsonToQueryParams(this.state.parameters);
		let url = "/booking/accommodations?" + _filtersUrl;
		$managementAxios.get(url).then((response) => {
			response.data.content?.forEach((element) => {
				element.bookingDate = new moment(new Date(element.bookingDate)).format(
					"DD-MM-YYYY HH:mm A"
				);
				element.checkInDate = new moment(element.checkInDate).format(
					"DD-MM-YYYY"
				);
				element.checkOutDate = new moment(element.checkOutDate).format(
					"DD-MM-YYYY"
				);
			});
			this.setState({
				rowData: response.data.content,
				data: response.data,
				loading: false,
			});
		});
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.setState(
			{ parameters: { ...this.state.parameters, pageNo: page } },
			() => {
				this.updateParameters();
				this.getData();
			}
		);
	};

	onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage });
		this.setState(
			{
				parameters: { ...this.state.parameters, pageSize: currentRowsPerPage },
			},
			() => {
				this.updateParameters();
				this.getData();
			}
		);
	};

	// viewBookingDetails = (data) => {
	// 	this.setState({
	// 		redirect: "/reservation/propdetails",
	// 		selectedId: data.id,
	// 		...this.state.parameters,
	// 	});
	// };

	handleStatusChange = (newStatus) => {
		this.setState(
			{
				bookingConfirmation: newStatus,
				parameters: {
					...this.state.parameters,
					bookingConfirmation: newStatus,
				},
			},
			() => {
				this.updateParameters();
			}
		);
	};

	handleChange = (update) => {
		if (update !== null) {
			this.setState({ dateRange: update });
			let _fromDate = moment(update[0]).format("DD-MM-YYYY");
			let _toDate = moment(update[1]).format("DD-MM-YYYY");
			this.setState(
				{
					fromDate: _fromDate,
					toDate: _toDate,
					parameters: {
						...this.state.parameters,
						fromDate: _fromDate,
						toDate: _toDate,
					},
				},
				() => {
					this.updateParameters();
				}
			);
		}

		if (update === null) {
			this.setState({ dateRange: [null, null] });
			let _fromDate = null;
			let _toDate = null;
			this.setState(
				{
					fromDate: _fromDate,
					toDate: _toDate,
					parameters: {
						...this.state.parameters,
						fromDate: _fromDate,
						toDate: _toDate,
					},
				},
				() => {
					this.updateParameters();
				}
			);
		}
	};

	search = () => {
		this.getData();
	};

	resetFilter = () => {
		this.setState(
			{
				parameters: {
					pageNo: 1,
					pageSize: 20,
					bookingConfirmation: "PENDING",
					bookingCode: '',
					sortBy: "id:desc",
					fromDate: null,
					toDate: null,
					guestName: '',
					guestPhone: '',
					guestEmail: '',
				},
				bookingConfirmation: "PENDING",
				bookingCode: '',
				fromDate: null,
				toDate: null,
				dateRange: [null, null],
			},
			() => {
				this.updateParameters();
				this.getData();
			}
		);
	};

	copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
	};

	bookingDataToExport = (data) => {
		let exportData = [];
		data.forEach((element) => {
			exportData.push({
				"Booking Code": element.bookingCode,
				"Booking Date": element.bookingDate || "",
				"CheckIn Date": element.checkInDate || "",
				"CheckOut Date": element.checkOutDate || "",
				Guest: `${element.guestAccount?.firstName || ""}  ${
					element.guestAccount?.lastName || ""
				}`,
				"Guest Phone": element.guestAccount?.phoneNumber || "",
				Total: element.feesTotal || 0.0,
				Discount: element.discount || 0.0,
				Reward: element.reward || 0.0,
				"Paid Amount": element.amountPaid || 0.0,
				Status: element.bookingStatus || "",
				Property: element.property?.name || "",
				Host: `${element.hostAccount?.firstName || ""}  ${
					element.hostAccount?.lastName || ""
				}`,
				"Host Phone": element.hostAccount?.phoneNumber || "",
			});
		});
		this.setState({ exportData: exportData });
		let fileName = `${moment().format("DD-MM-YYYY HH:mm:A ")}_BookingData.csv`;
		JsonToCsvFile(exportData, fileName);
	};

	exportData = () => {
		var _filter = { ...this.state.parameters };
		 _filter.pageNo = null;
		 _filter.pageSize = null;

		var _filtersUrl = JsonToQueryParams(_filter);
		var url = "/booking/accommodations?" + _filtersUrl;
		$managementAxios.get(url).then((response) => {
			response.data.forEach((element) => {
				element.bookingDate = new moment(new Date(element.bookingDate)).format(
					"DD/MM/YYYY HH:mm A"
				);
				element.checkInDate = new moment(element.checkInDate).format(
					"DD/MM/YYYY"
				);
				element.checkOutDate = new moment(element.checkOutDate).format(
					"DD/MM/YYYY"
				);
			});
			//localStorage.removeItem("bookingParameters");
			this.bookingDataToExport(response.data);
		});
	};

	viewBookingDetails = (data) => {
		let _guest = this.getGuestString(data);

		data["guest"] = _guest;
		this.setState({ selectedBooking: data }, () => {
			this.setDrawerOpen(true);
		});
	};

	getGuestString = (data) => {
		let result = [];

		if (data.numberOfAdults > 0) {
			result.push(
				`${data.numberOfAdults} Adult${data.numberOfAdults > 1 ? "s" : ""}`
			);
		}
		if (data.numberOfChildren > 0) {
			result.push(
				`${data.numberOfChildren} Child${
					data.numberOfChildren > 1 ? "ren" : ""
				}`
			);
		}
		if (data.numberOfInfants > 0) {
			result.push(
				`${data.numberOfInfants} Infant${data.numberOfInfants > 1 ? "s" : ""}`
			);
		}

		return result.join(", ");
	};

	Clipboard = (props) => {
		return (
			<Whisper
				placement='top'
				trigger='click'
				speaker={<Tooltip>Copied!!</Tooltip>}
			>
				<i
					className='fa fa-solid fa-copy cursor-pointer m-1'
					onClick={() => {
						navigator.clipboard.writeText(props.value);
					}}
				></i>
			</Whisper>
		);
	};

	StatusBadge = (props) => {
		let _status = props.value;
		let _badge = "badge badge-";
		switch (_status) {
			case "PENDING":
				_badge += "warning";
				break;
			case "ACCEPTED":
				_badge += "success";
				break;
			case "DECLINED":
				_badge += "danger";
				break;
			case "CONFIRMED":
				_badge += "primary";
				break;
			case "CANCELLED":
				_badge += "secondary";
				break;
			default:
				_badge += "info";
				break;
		}
		return <span className={_badge}>{_status}</span>;
	};

	render() {
		const { Clipboard,StatusBadge } = this;
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect,
						state: {
							property_id: this.state.selectedId,
							...this.state.parameters,
						},
					}}
				/>
			);
		}
		return (
			<Fragment>
				<Breadcrumb options={this.state.breadcrumb} />
				<div className='container-fluid'>
					<div className='d-flex justify-content-between mb-3'>
						<h4>Property Bookings</h4>
						<button className='btn btn-primary' onClick={this.exportData}>
							<i className='fa fa-download pr-2'></i>
							Export
						</button>
					</div>
					<div className='d-flex justify-content-end my-2 row'>
						<div className='col pr-1'>
							<DateRangePicker
								size='lg'
								onChange={this.handleChange}
								placeholder='DD/MM/YYYY - DD/MM/YYYY'
								format='dd/MM/yyyy'
							/>
						</div>
						<div className='col p-0 pr-1'>
							<input
								type='text'
								className='form-control'
								placeholder='Booking Code'
								value={this.state.bookingCode}
								onChange={(e) => {
									this.setState({
										bookingCode: e.target.value,
										parameters: {
											...this.state.parameters,
											bookingCode: e.target.value,
										},
									});
								}}
							/>
						</div>
						<div className='col p-0 pr-1'>
							<input
								type='text'
								className='form-control'
								placeholder='Guest Email'
								value={this.state.parameters.guestEmail}
								onChange={(e) => {
									this.setState({
										parameters: {
											...this.state.parameters,
											guestEmail: e.target.value,
										},
									});
								}}
							/>
						</div>
						<div className='col p-0 pr-1'>
							<input
								type='text'
								className='form-control'
								placeholder='Guest Phone'
								value={this.state.parameters.guestPhone}
								onChange={(e) => {
									this.setState({
										parameters: {
											...this.state.parameters,
											guestPhone: e.target.value,
										},
									});
								}}
							/>
						</div>

						<div className='col p-0 pr-1'>
							<input
								type='text'
								className='form-control'
								placeholder='Guest Name'
								value={this.state.parameters.guestName}
								onChange={(e) => {
									this.setState({
										parameters: {
											...this.state.parameters,
											guestName: e.target.value,
										},
									});
								}}
							/>
						</div>
						<div className='col p-0 pr-1'>
							<select
								className='form-control xl'
								id='bookingConfirmationDropdown'
								defaultValue={this.state.bookingConfirmation}
								onChange={(e) => {
									this.setState({
										bookingConfirmation: e.target.value,
										parameters: {
											...this.state.parameters,
											bookingConfirmation: e.target.value,
										},
									});
								}}
							>
								<option value='PENDING'>PENDING</option>
								<option value='ACCEPTED'>ACCEPTED</option>
								<option value='DECLINED'>DECLINED</option>
								<option value='CONFIRMED'>CONFIRMED</option>
								<option value='CANCELLED'>CANCELLED</option>
							</select>
						</div>
						<div className='col pl-0 d-flex flex-row justify-content-end'>
							<button className='btn btn-primary mr-1' onClick={() => this.search()}>
								<i className='fa fa-search'></i>
								Search
							</button>
							<button
								className='btn btn-danger pr-3 pl-3'
								onClick={() => this.resetFilter()}
							>
								<i className='fa fa-refresh'></i>
							</button>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<DataTable
								keyField='id'
								columns={this.state.columns}
								data={this.state.rowData}
								pagination={true}
								paginationServer={true}
								paginationPerPage={this.state.parameters.pageSize}
								paginationTotalRows={this.state.data.totalElements}
								onChangePage={this.onChangePages}
								onChangeRowsPerPage={this.onChangePerPage}
								progressPending={this.state.loading}
								paginationDefaultPage={this.state.parameters.pageNo}
								dense
								pointerOnHover={true}
								highlightOnHover={true}
								selectableRowsSingle={true}
								onRowClicked={this.viewBookingDetails}
							/>
						</div>
					</div>
				</div>
				<Drawer
					open={this.state.isDrawerOpen}
					onClose={() => this.setDrawerOpen(false)}
					size='lg'
				>
					<Drawer.Body>
						<div className='row'>
							{this.state.selectedBooking && (
								<div className='col-sm-12 mt-4'>
									<div className='card border mb-0'>
										<div className='card-header bg-light text-dark'>
											<h5 className='mb-0'>
												Booking Details <br />
												<small className='text'>
													#{this.state.selectedBooking?.bookingCode}
												</small>
												<Clipboard
													value={this.state.selectedBooking?.bookingCode}
												/>
											</h5>
										</div>
										<div className='card-body'>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Booking ID</div>
												<div className='col-sm-8'>
													#{this.state.selectedBooking?.bookingCode}
													<Clipboard
														value={this.state.selectedBooking?.bookingCode}
													/>
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Booking Date</div>
												<div className='col-sm-8'>
													{this.state.selectedBooking?.bookingDate || null}
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Check-in</div>
												<div className='col-sm-8'>
													{this.state.selectedBooking?.checkInDate || null}
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Check-out</div>
												<div className='col-sm-8'>
													{this.state.selectedBooking?.checkOutDate || null}
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Guests</div>
												<div className='col-sm-8'>
													{this.state.selectedBooking?.guest || null}
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Status</div>
												<div className='col-sm-8'>
													<StatusBadge
														value={this.state.selectedBooking?.bookingStatus}
													/>
												</div>
											</div>
											<hr />
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Total Amount</div>
												<div className='col-sm-8'>
													{this.state.selectedBooking?.feesTotal || 0} ৳
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>Discount</div>
												<div className='col-sm-8'>
													-{this.state.selectedBooking?.discount || 0} ৳
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>
													Applied Rewards
												</div>
												<div className='col-sm-8'>
													-{this.state.selectedBooking?.reward || 0} ৳
												</div>
											</div>
											<div className='row mb-3'>
												<div className='col-sm-4 text-muted'>
													Payable Amount
												</div>
												<div className='col-sm-8'>
													<strong>
														{this.state.selectedBooking?.amountPaid || 0} ৳
													</strong>
												</div>
											</div>
										</div>
									</div>
									<CardGroup columns={2} spacing={20} className='mt-3'>
										<Card key={1} bordered direction='row'>
											<img
												src={
													this.state.selectedBooking.hostAccount
														.profilePhotoPath || AvaterOne
												}
												alt='host'
												width={100}
												style={{ objectFit: "cover" }}
											/>
											<VStack spacing={1}>
												<Card.Header>
													<Text size='xxl'>Host</Text>
												</Card.Header>
												<Card.Body>
													<VStack spacing={1}>
														<Text weight='bold'>
															{this.state.selectedBooking.hostAccount
																.firstName || ""}{" "}
															{this.state.selectedBooking.hostAccount
																.lastName || ""}
														</Text>

														{this.state.selectedBooking.hostAccount
															.phoneNumber && (
															<Text muted size='sm'>
																<i
																	className='fa fa-phone mr-2'
																	aria-hidden='true'
																></i>
																{this.state.selectedBooking.hostAccount
																	.phoneNumber || ""}
																<Clipboard
																	value={
																		this.state.selectedBooking?.hostAccount
																			?.phoneNumber || ""
																	}
																/>
															</Text>
														)}
														{this.state.selectedBooking.hostAccount
															.emailAddress && (
															<Text muted size='sm'>
																<i
																	className='fa fa-envelope-o mr-2'
																	aria-hidden='true'
																></i>
																{this.state.selectedBooking.hostAccount
																	.emailAddress || ""}
																<Clipboard
																	value={
																		this.state.selectedBooking?.hostAccount
																			?.emailAddress || ""
																	}
																/>
															</Text>
														)}
													</VStack>
												</Card.Body>
											</VStack>
										</Card>
										<Card
											key={2}
											bordered
											direction='row'
											className='justify-content-between'
										>
											<VStack spacing={1}>
												<Card.Header>
													<Text size='xxl'>Guest</Text>
												</Card.Header>
												<Card.Body>
													<VStack spacing={1}>
														<Text weight='bold'>
															{this.state.selectedBooking.guestAccount
																.firstName || ""}{" "}
															{this.state.selectedBooking.guestAccount
																.lastName || ""}
														</Text>
														{this.state.selectedBooking.guestAccount
															.phoneNumber && (
															<Text muted size='sm'>
																<i
																	className='fa fa-phone mr-2'
																	aria-hidden='true'
																></i>
																{this.state.selectedBooking.guestAccount
																	.phoneNumber || ""}
																<Clipboard
																	value={
																		this.state.selectedBooking?.guestAccount
																			?.phoneNumber || ""
																	}
																/>
															</Text>
														)}

														{this.state.selectedBooking.guestAccount
															.emailAddress && (
															<Text muted size='sm'>
																<i
																	className='fa fa-envelope-o mr-2'
																	aria-hidden='true'
																></i>
																{this.state.selectedBooking.guestAccount
																	.emailAddress || ""}
																<Clipboard
																	value={
																		this.state.selectedBooking?.guestAccount
																			?.emailAddress || ""
																	}
																/>
															</Text>
														)}
													</VStack>
												</Card.Body>
											</VStack>
											<img
												src={
													this.state.selectedBooking.guestAccount
														.profilePhotoPath || AvaterOne
												}
												alt='guest'
												width={100}
												style={{ objectFit: "cover" }}
											/>
										</Card>
									</CardGroup>
									<Card className='w-100 mt-3' bordered direction='row'>
										<img
											className='image-booking-detail'
											src={
												this.state.selectedBooking.property.coverPhoto ||
												AvaterOne
											}
											alt='Shadow'
											style={{ objectFit: "cover" }}
										/>
										<VStack spacing={2}>
											<Card.Header as='h5'>
												{this.state.selectedBooking.property.name || null}
											</Card.Header>
											<Card.Body>
												<p className='m-0'>
													<i
														className='fa fa-map-marker pr-2'
														aria-hidden='true'
													></i>
													{this.state.selectedBooking.property.address || null}
												</p>
												<p className='m-0'>
													<i className='fa fa-usd pr-2' aria-hidden='true'></i>
													{this.state.selectedBooking.property.price || 0} tk /
													night
												</p>
												<p className='m-0'>
													<i
														className='fa fa-user-o pr-2'
														aria-hidden='true'
													></i>
													{this.state.selectedBooking.hostAccount.firstName ||
														null}{" "}
													{this.state.selectedBooking.hostAccount.lastName ||
														null}
												</p>
												{this.state.selectedBooking.hostAccount.phoneNumber && (
													<p className='m-0'>
														<i
															className='fa fa-phone pr-2'
															aria-hidden='true'
														></i>
														{this.state.selectedBooking.hostAccount
															.phoneNumber || null}
													</p>
												)}

												{this.state.selectedBooking.hostAccount
													.emailAddress && (
													<p className='m-0'>
														<i
															className='fa fa-envelope-o pr-2'
															aria-hidden='true'
														></i>
														{this.state.selectedBooking.hostAccount
															.emailAddress || null}
													</p>
												)}
											</Card.Body>
											<Card.Footer></Card.Footer>
										</VStack>
									</Card>
								</div>
							)}
						</div>
					</Drawer.Body>
				</Drawer>
			</Fragment>
		);
	}
}

export default PropertiesBookingReqList;
